import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import PostLink from "../components/post-link"

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout pageName="Blog Posts">
      <SEOComponent  title="Blog Posts" />
      <div className="columns m-0 pt-4">
        <div className="column">
          <h2 className="title is-2">My latest blog posts:</h2>
          <ul>{Posts}</ul>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`